@import '../../styles/customMediaQueries.css';


.root {
  padding: 20vh 36px;
}

.content {
  text-align: center;
  max-width: 650px;
  margin: 0 auto;
}

.heroHolder {
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  width: 100%;
  position: relative;
  min-height: 85vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.backgroundImageWrapper {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-image: url("./background.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.sectionContent {
  padding: 32px 0;
  position: relative;
  word-break: break-word;

  @media (--viewportMedium) {
    padding: 64px 0;
  }
}

.sectionDetails {
  max-width: var(--contentMaxWidthPages);
  display: grid;
  justify-content: start;
  margin: 0 auto;
  padding: 0 32px;
  position: relative;

  @media (--viewportMedium) {
    justify-content: center;
  }
}

.title {
  color: var(--colorSuccessLight);
  justify-self: center;
  text-align: center;
  font-size: 36px;
  line-height: 43px;
}

.description {
  margin-bottom: 40px;
  max-width: 65ch;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  text-align: center;
  color: var(--colorSuccessLight);

}

/* Search */
.searchFormHolder {
  position: relative;
  height: 48px;
}

.searchLink {
  min-width: 320px;
  height: 100%;
  border: none;
  background: white;
  border-radius: 50px;
}

.search {
  font-weight: var(--fontWeightMedium);
  font-size: 15px;
  line-height: 24px;
  letter-spacing: 0;
  color: var(--colorGrey700);

  display: inline-block;
  margin: 28px 0;
  text-decoration: inherit;

  @media (--viewportMedium) {
    font-weight: var(--fontWeightMedium);
  }
}



.takeAvailableSpace {
  flex-grow: 1;

  /* At 1024px, the translations might need more space than what searchLink otherwise gets. */
  min-width: 220px;
}

.topbarSearchWithLeftPadding {
  padding-left: 24px;
  padding-right: 24px;
  height: var(--heroSearchHeightDesktop);

  @media (--viewportLarge) {
    padding-left: 34px;
  }
}
